.header-mobile {
  .container {
    padding-left: 0;
  }
}

.mobile-menu {
  position: fixed;
  width: 100%;
  // height: calc(100% - 72px);
  height: calc(100%);
  background-color: $black;
  transform: translateY(-150%);
  // top: 72px;
  top: 0;
  left: 0;
  overflow: hidden;
  padding: 16px;
  padding-top: 0;
  z-index: 90;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;

  .mobile-menu-bottom {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    overflow: auto;
  }

  .bottom-content {
    display: flex;
    flex-direction: column;
    .bottom-links {
      display: flex;
      align-items: center;
      justify-content: center;
      .link {
        color: $white;
        font-size: 14px;
        font-weight: 400;
        line-height: 22.4px;
        padding: 12px;
        padding-top: 43px;
        text-align: center;
        text-decoration: none;
        padding-bottom: 0;
        text-decoration: underline;
      }
    }
    .copyright {
      color: $white;
      font-size: 14px;
      font-weight: 400;
      line-height: 22.4px;
      text-align: center;
      padding-bottom: 40px;
      margin-top: 24px;
    }
  }

  .nav-list {
    .nav-item {
      a {
        color: $white;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        padding: 20px 0;
        &::after {
          content: '';
          display: block;
          width: 24px;
          height: 24px;
          background-image: url(/images/chevron-right.svg);
        }
      }
    }
  }
}

.mobile-menu-open {
  overflow: hidden;
  .header,
  .header-mobile {
    background-color: $black;
    .logo img.mobile-open {
      display: block;
    }
    .logo img.default {
      display: none;
    }
  }
  .mobile-menu {
    transform: translateY(0%);
    transition: transform 0.3s ease;
  }
}
