@keyframes drop-out-cookies {
  from {
    opacity: 1;
    transform: translateY(-50%);
  }

  to {
    opacity: 0;
    transform: translateY(100%);
  }
}

@keyframes drop-out-cookies-desktop {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(100%);
  }
}

@media screen and (max-width: $breakpoint-md) {
  .cookies-banner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 79;

    &.dissappearing {
      animation: fade-out 0.5s ease 200ms backwards;

      .cookies-banner {
        animation: drop-out-cookies 0.5s ease 200ms backwards !important;
      }
    }
  }
}

.cookies-banner-overlay {
  display: none;

  &.show {
    display: block;
  }

  &.dissappearing {
    .cookies-banner {
      animation: drop-out-cookies-desktop 0.5s ease 200ms backwards;
    }
  }
}

.cookies-banner {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 80;
  background-color: $bright-blue;
  padding: 24px;
  display: flex;
  max-width: 490px;
  @media screen and (min-width: 1281px) {
    right: calc((100% - 1281px) / 2 + 24px);
  }
  @media screen and (max-width: $breakpoint-md) {
    max-width: 100%;
    width: calc(100% - 16px * 2);
    left: 16px;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    height: 162px;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;

    .close-btn {
      margin-left: 0 !important;
      margin-top: 15px;
    }

    span {
      max-width: 222px;
    }
  }

  span {
    color: $white;
    font-size: 16px;
    line-height: 25.6px;
    font-weight: 400;
  }

  .close-btn {
    color: $white;
    font-size: 16px;
    line-height: 25.6px;
    font-weight: 500;
    text-transform: uppercase;
    white-space: nowrap;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-left: 32px;
  }
}
