.footer {
  background-color: $black;
  display: flex;
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 147px;
    width: 100%;

    .bottom-links {
      display: flex;
      align-items: center;
      justify-content: center;
      .link {
        color: $white;
        font-size: 14px;
        font-weight: 400;
        line-height: 22.4px;
        padding: 30px;
        padding-top: 0;
        padding-bottom: 0;
        // padding-top: 43px;
        text-align: center;
        text-decoration: none;
        // padding-bottom: 0;
        text-decoration: underline;
      }
    }
    .logo {
      height: 100%;
      display: flex;
      align-items: center;
      img {
        width: 412px;
      }
    }
    .copyright {
      span {
        color: $white;
        font-size: 14px;
        font-weight: 700;
        line-height: 22.4px;
      }
    }

    @media screen and (max-width: $breakpoint-xl) {
      .logo {
        img {
          max-width: 314px;
        }
      }
    }

    @media screen and (max-width: $breakpoint-lg) {
      .logo {
        img {
          max-width: 230px;
          min-width: 230px;
        }
      }

      .bottom-links {
        display: none;
      }
    }
    @media screen and (max-width: $breakpoint-md) {
      flex-direction: column;
      height: 106px;
      justify-content: center;
      .copyright {
        span {
          font-weight: 400;
        }
      }
    }
  }
}
