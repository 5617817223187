$breakpoint-xs: 560px;
$breakpoint-sm: 658px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1100px;
$breakpoint-xxl: 1400px;

$white: #ffffff;
$bright-blue: #337ab8;
$light-blue: #e9f3fb;
$black: #10283d;
$red: #c63335;
