.investment-wrapper {
  display: flex;
  gap: 24px;
  width: 100%;

  &.top-line {
    & > div {
      border-top: 2px solid $black;
      padding: 28px 0;
    }
  }

  .btn-link {
    width: max-content;
    display: block;
  }

  ul.list {
    li {
      margin-left: 18px;
      padding-left: 7px;
      font-size: 20px;
      line-height: 32px;
      font-weight: 400;
      &:not(:last-child) {
        margin-bottom: 8px;
      }
      &::marker {
        content: '—';
      }
    }
  }

  .title-wrapper {
    width: 50%;
  }
  .text-wrapper {
    min-width: 50%;
    width: 50%;
  }
}

@media screen and (max-width: $breakpoint-md) {
  .investment-wrapper {
    flex-direction: column;
    gap: 0;

    &.mb-20 {
      margin-bottom: 57px;
    }

    & > .h2 {
      margin-top: 32px;
    }
    .title-wrapper {
      width: 100%;
      padding-bottom: 60px;
      margin-top: 24px;
    }
    .text-wrapper {
      min-width: 100%;
      width: 100%;

      .btn-link {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .investment {
    padding-top: 32px !important;
    padding-bottom: 32px !important;
    .title-wrapper {
      padding-bottom: 0 !important;
      margin-bottom: 40px;
    }
    .text-wrapper {
      border: none !important;
      padding-top: 0 !important;
    }
    .investment-wrapper {
      &.top-line {
        &:last-child {
          .text-wrapper {
            padding-bottom: 0 !important;
          }
        }
      }
      .title-wrapper {
        margin-bottom: 24px !important;
      }
      .text-wrapper {
        padding-bottom: 40px;
        .list {
          li {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }

  .investment-wrapper,
  .investment {
    .h1 {
      font-size: 32px;
      line-height: 40px;
    }
    .title-wrapper {
      padding-bottom: 24px;
      margin-top: 0;
    }

    .text-wrapper {
      p {
        margin-bottom: 0;
      }
      &.multi-line {
        p:not(:last-child) {
          margin-bottom: 24px;
        }
        p.mb-10 {
          margin-bottom: 32px;
        }
      }
    }
  }
}
