body.bg-lines {
  background-image: url(/images/lines.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media screen and (max-width: $breakpoint-md) {
    background-image: none;
  }
}

.contact-page {
  min-height: calc(100vh - 147px - 147px);
  @media screen and (max-width: $breakpoint-lg) {
    min-height: calc(100vh - 72px - 147px);
  }

  @media screen and (max-width: $breakpoint-md) {
    min-height: calc(100vh - 72px - 106px);
  }

  @media screen and (max-width: $breakpoint-md) {
    flex-direction: column;
    gap: 0;
    // margin-top: 24px;
    .title-wrapper {
      padding-bottom: 60px;
    }
  }
}

.contact-wrapper {
  .form-success {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    .h1 {
      margin-bottom: 32px;
      @media screen and (max-width: $breakpoint-md) {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }

  &.top-line {
    display: flex;
    gap: 24px;
    & > div {
      border-top: 2px solid $black;
      padding: 32px 0;
    }

    .title-wrapper {
      max-width: 411px;
      width: 100%;
    }
  }

  .form-wrapper {
    width: 100%;
    min-width: 435px;

    #reason {
      display: none;
    }

    .checkbox-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      white-space: nowrap;

      .link {
        color: $black;
        font-size: 20px;
        line-height: 32px;
        font-weight: 400;
        text-decoration: underline;
        margin-left: 5px;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .checkbox {
        &.error {
          .checkbox__checkmark {
            border-color: $red;
          }
        }
      }

      .checkbox input:checked ~ .checkbox__checkmark {
        background-color: $bright-blue;
        border: none;
      }
      .checkbox input:checked ~ .checkbox__checkmark:after {
        opacity: 1;
      }
      .checkbox:hover input ~ .checkbox__checkmark {
        background-color: $white;
      }
      .checkbox:hover input:checked ~ .checkbox__checkmark {
        background-color: $bright-blue;
        border: none;
      }
      .checkbox .checkbox__checkmark {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        height: 20px;
        width: 20px;
        background-color: $white;
        border: 1px solid $black;
        transition: background-color 0.25s ease;
        border-radius: 4px;
        cursor: pointer;
      }
      .checkbox .checkbox__checkmark:after {
        content: '';
        position: absolute;
        left: 7px;
        top: 3px;
        width: 5px;
        height: 9px;
        border: solid $white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        opacity: 0;
        transition: opacity 0.25s ease;
      }

      .checkbox .checkbox__body {
        font-size: 20px;
        line-height: 25px;
        margin-left: 32px;
        p {
          margin: 0;
        }
      }
    }
    .row {
      display: flex;
      gap: 24px;
      margin-bottom: 24px;

      &.justify-between {
        justify-content: space-between;
        @media screen and (max-width: $breakpoint-xl) {
          flex-direction: column;
        }
      }
    }

    .field {
      width: 100%;

      textarea {
        width: 100%;
        height: 193px;
        width: 100%;
        padding: 20px 24px;
        font-size: 20px;
        line-height: 25px;
        font-weight: 400;
        border: 1px solid $black;
        font-family: $font-lexend;
        resize: none;
        &::placeholder {
          color: $black;
        }
      }
      input {
        width: 100%;
        padding: 20px 24px;
        font-size: 20px;
        line-height: 25px;
        font-weight: 400;
        border: 1px solid $black;
        font-family: $font-lexend;
        &::placeholder {
          color: $black;
        }
      }

      &.error {
        .dropdown,
        select {
          border-color: $red !important;
        }
        input,
        textarea {
          border-color: $red;
        }
        .text-error {
          color: $red;
          font-size: 14px;
          line-height: 22.4px;
          font-weight: 400;
          padding-top: 12px;
          display: block;
          margin-bottom: -12px;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .contact-page {
    #contact_form {
      .row {
        flex-direction: column;
      }
    }
  }
}

$tablet: 768px;

@mixin breakpoint($point) {
  @if $point == untilTablet {
    @media (max-width: $tablet - 1) {
      @content;
    }
  } @else if $point == tablet {
    @media (min-width: $tablet) {
      @content;
    }
  }
}

.dropdown {
  width: 100%;
  // padding: 20px 24px;
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  border: 1px solid $black;
  font-family: $font-lexend;

  // @include breakpoint(tablet) {
  // 	width: 500px;
  // }

  &__filter-selected {
    line-height: 25px;
    font-family: $font-lexend;
  }

  &__switch:checked + &__options-filter &__select {
    transform: scaleY(1);
    opacity: 1;
  }

  &__switch:checked + &__options-filter &__filter:after {
    transform: rotate(-135deg);
  }

  &__options-filter {
    width: 100%;
    cursor: pointer;
  }

  &__filter {
    position: relative;
    display: flex;
    padding: 20px 24px;
    color: $black;
    background-color: #fff;
    transition: 0.3s;
    list-style: none;

    &:focus {
      outline: none;
    }

    &::after {
      position: absolute;
      top: 45%;
      right: 20px;
      content: '';
      width: 10px;
      height: 10px;
      border-right: 2px solid $black;
      border-bottom: 2px solid $black;
      transform: rotate(45deg) translateX(-45%);
      transition: 0.3s ease-in-out;
    }
  }

  &__select {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: 5px;
    overflow: hidden;
    border: 1px solid $black;
    transform: scaleY(0);
    transform-origin: top;
    opacity: 0;
    transition: 0.2s ease-in-out;
    z-index: 10;
  }

  &__select-option {
    padding: 20px;
    background-color: #fff;
    border-bottom: 1px solid #e9ecef;
    transition: 0.3s;

    &:last-of-type {
      border-bottom: 0;
    }

    &:hover {
      background-color: #e9ecef;
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .dropdown {
    display: none;
  }

  .select {
    position: relative;
    &::after {
      position: absolute;
      top: 45%;
      right: 20px;
      content: '';
      width: 10px;
      height: 10px;
      border-right: 2px solid $black;
      border-bottom: 2px solid $black;
      transform: rotate(45deg) translateX(-45%);
      transition: 0.3s ease-in-out;
    }
  }
  #reason {
    display: block !important;
    width: 100%;
    padding: 20px 24px;
    font-size: 20px;
    line-height: 25px;
    font-weight: 400;
    border: 1px solid $black;
    appearance: none;
    position: relative;
    background-color: $white;
    color: $black;
    &::placeholder {
      color: $black;
    }
  }
}

.contact-page {
  @media screen and (max-width: $breakpoint-md) {
    flex-direction: column;
    gap: 0;
    // margin-top: 24px;
    .contact-wrapper {
      margin-bottom: 0;
      .title-wrapper {
        padding: 0;
        margin-bottom: 24px !important;
      }
    }
    .contact-wrapper.top-line {
      display: flex;
      flex-direction: column;
      gap: 0;
      & > div {
        border-top: 2px solid $black;
        padding: 32px 0;
        max-width: 100%;
        width: 100%;
      }
      .form-wrapper {
        min-width: 100%;
        max-width: 100%;
        .row {
          flex-direction: column;
        }
      }
      .form-wrapper .checkbox-wrapper {
        flex-direction: column;
        align-items: flex-start;
        .checkbox__body p {
          font-size: 20px;
        }
        .link {
          margin-left: 32px;
        }
      }
    }
    // .title-wrapper {
    //   padding-bottom: 60px;
    // }
  }
}
