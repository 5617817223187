.page-privacy {
  padding-left: 210px;
  @media screen and (max-width: $breakpoint-lg) {
    padding-left: 110px;
  }

  .date-desc {
    margin-top: 40px;
    margin-bottom: 80px;
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
    text-transform: uppercase;
  }

  h2 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 500;
    margin-bottom: 24px;
    text-transform: uppercase;
  }

  ol {
    max-width: 860px;
    list-style: none;
    margin-left: -25px;
    ol {
      list-style: none;
      margin-left: 0;

      li {
        margin-left: 0;
        & > p {
          margin-left: 0;
        }
      }
      ul {
        list-style: disc;
        padding-left: 20px;
        margin-top: -30px;
        li {
          font-size: 20px;
          font-weight: 400;
          margin: 0;
        }
      }
    }
    li {
      margin-left: 20px;
      padding-left: 5px;
      margin-bottom: 80px;
      &::marker {
        padding-left: 10px;
        font-size: 23px;
        line-height: 30px;
      }
      h2 {
        font-size: 24px;
        line-height: 30px;
        font-weight: 500;
        margin-bottom: 24px;
        text-transform: uppercase;
      }
    }
  }
  @media screen and (max-width: $breakpoint-md) {
    padding-left: 0;
    .date-desc {
      font-size: 20px;
    }
    h2 {
      font-size: 20px;
    }
    ol {
      ul {
        &.long-links {
          li a {
            word-wrap: break-word;
          }
        }
        li {
          font-size: 16px !important;
          line-height: 20px;
        }
      }
      li {
        h2 {
          font-size: 20px;
        }
      }
    }
  }
}
