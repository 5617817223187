@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Lexend:wght@100..900&display=swap');

$font-dm-serif: 'DM Serif Display', serif;
$font-lexend: 'Lexend', sans-serif;

.hero-title {
  font-family: $font-dm-serif;
  font-weight: 400;
  font-size: 64px;
  line-height: 72px;
}

html {
  font-family: $font-lexend;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
}
