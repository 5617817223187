@media screen and (min-width: $breakpoint-lg) {
  .header.transparent-bg {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    .logo {
      img.desktop-dark {
        display: block;
      }
    }
    .logo {
      img.default {
        display: none;
      }
    }
    .nav-list {
      .nav-item a,
      .nav-item span {
        color: $white !important;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .header.transparent-bg {
    img.default {
      display: block !important;
    }
    .header__inner .logo img,
    .header-mobile__inner .logo img {
      &.desktop-dark {
        display: none !important;
      }
    }
  }
}

.header,
.header-mobile {
  overflow: hidden;

  .logo {
    display: flex;
    align-items: center;

    img.mobile-open,
    img.desktop-dark {
      display: none;
    }
  }

  @media screen and (max-width: $breakpoint-xl) {
    .logo {
      img {
        max-width: 314px;
      }
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 147px;

    @media screen and (max-width: $breakpoint-lg) {
      height: 72px;

      .logo {
        img {
          max-width: 245px;
        }
      }
    }

    @media screen and (max-width: $breakpoint-md) {
      height: 72px;

      .logo {
        img {
          max-width: 230px;
          min-width: 230px;
        }
      }
    }
  }

  .menu.desktop {
    @media screen and (max-width: $breakpoint-lg) {
      display: none;
    }

    nav {
      display: flex;
      align-items: center;
    }

    .nav-list {
      display: flex;
      list-style: none;
      margin-right: 24px;

      .nav-item {
        background-position: center;
        overflow: visible;
        position: relative;
        margin-left: -15px;

        &:nth-child(1) {
          span::before {
            background-image: url(/images/Vector1.svg);
          }
        }

        &:nth-child(2) {
          span::before {
            background-image: url(/images/Vector2.svg);
          }
        }

        &:nth-child(3) {
          span::before {
            background-image: url(/images/Vector3.svg);
          }
        }

        & {
          span::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }

      a,
      span {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        text-transform: uppercase;
        padding: 15px 30px;
        color: $black;
        text-decoration: none;
        display: block;
      }
    }
  }

  .menu.mobile {
    display: none;

    @media screen and (max-width: $breakpoint-lg) {
      display: block;
    }

    .menuToggle {
      width: 40px;
      height: 32px;

      input {
        display: block;
        width: 40px;
        height: 32px;
        position: absolute;
        top: -7px;
        left: -5px;

        cursor: pointer;

        opacity: 0;
        /* hide this */
        z-index: 2;
        /* and place it over the hamburger */

        -webkit-touch-callout: none;
      }

      span {
        display: block;
        width: 33px;
        height: 3px;
        margin-bottom: 7px;
        position: relative;

        background: $black;
        border-radius: 3px;

        z-index: 1;

        transform-origin: 4px 0px;

        transition:
          transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
          background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
          opacity 0.55s ease,
          margin 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      }

      input:checked ~ span:nth-last-child(2) {
        transform: rotate(-45deg) translate(-50%, 1px);
      }

      input:checked ~ span:nth-last-child(3) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }

      input:checked ~ span {
        opacity: 1;
        transform: rotate(45deg) translate(-50%, -5px);
        background: $white;
        margin-right: -20px;
      }
    }
  }
}
