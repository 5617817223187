.hero-banner {
  position: relative;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-align: center;
  // height: calc(100vh - 147px);
  height: 100vh;
  min-height: 600px;
  .play-btn {
    display: none;
    position: absolute;
    right: 80px;
    bottom: 80px;
    width: 100px;
    height: 100px;
    background-image: url(/images/play.svg);
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 10;
    &.mobile {
      display: none;
    }
    &.show.desktop {
      animation: fade-in 0.5s ease 200ms backwards;
      display: block;
    }
  }
  .video-box {
    width: 100%;

    video {
      width: 100%;
      height: calc(100vh - 147px);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .video-overlay {
    background-image: url(/images/ellipse.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .content-wrapper {
    position: absolute;
    top: calc(50% + 147px);
    transform: translateY(calc(-50% - 147px));
    left: 0;
    @media screen and (min-width: 1281px) {
      left: calc((100% - 1281px) / 2);
    }
    text-align: left;
    color: $white;
    background-size: contain;
    background-repeat: no-repeat;
    padding-left: 20px;

    .btn {
      width: fit-content;
    }

    h1 {
      margin-bottom: 32px;
    }
    p {
      max-width: 674px;
      font-size: 24px;
      line-height: 38.4px;
      margin-bottom: 60px;
    }
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .hero-banner {
    .content-wrapper {
      .btn {
        width: 100% !important;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .play-btn {
    &.show.mobile {
      display: block;
      position: static;
      top: unset;
      left: unset;
      margin-bottom: 40px;
    }

    &.desktop {
      display: none;
      &.show {
        display: none !important;
      }
    }
  }
  .hero-banner {
    height: calc(100vh - 72px);
    flex-direction: column;
    .content-wrapper {
      top: unset;
      bottom: 0;
      transform: none;
      left: 0;
      padding: 20px;
      padding-bottom: 120px;
      width: 100%;
      .btn {
        font-size: 16px;
        padding: 18px;
        text-align: center;
      }
      h1 {
        font-size: 40px;
        line-height: 44px;
        margin-bottom: 24px;
      }
      p {
        font-size: 16px;
        line-height: 25.6px;
        margin-bottom: 32px;
      }
      button.btn {
        font-size: 16px;
        padding: 11px 21px;
        white-space: nowrap;
      }
    }
    .video-box {
      video {
        height: calc(100vh - 72px);
      }
    }
  }
}
