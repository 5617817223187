/**
 * Common styles
 */

//  body {
//   transform: scale(0.8);
//   transform-origin: 0 0;
// }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  color: $black;
}

.container {
  max-width: 1281px;
  margin: 0 auto;
  padding: 0 16px;
}

.link {
  color: $bright-blue;
  font-weight: 500;
}

.arrow-text {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  text-decoration: none;
  color: $black;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  &::before {
    content: '';
    display: block;
    width: 32px;
    height: 32px;
    background-image: url(/images/arrow-circle-right.svg);
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 16px;
  }
}

.btn-link {
  font-family: $font-lexend;
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
  text-transform: uppercase;
  border: none;
  display: block;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  color: $white;
  background-color: $bright-blue;
  padding: 18px 24px;
  border-radius: 8px;
  &:disabled {
    opacity: 0.5;
  }
  @media screen and (max-width: $breakpoint-lg) {
    font-size: 16px;
    line-height: 20px;
    padding: 18px 21px;
  }
}

.btn {
  font-family: $font-lexend;
  font-size: 20px;
  line-height: 25px;
  font-weight: 500;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;
  display: block;
  border-radius: 8px;
  &.btn-white {
    color: $bright-blue;
    background-color: $white;
    padding: 18px 24px;
  }
}

.h2 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  @media screen and (max-width: $breakpoint-md) {
    font-size: 20px;
    line-height: 25px;
  }
}

.h1 {
  font-size: 48px;
  line-height: 56px;
  font-weight: 400;
  font-family: $font-dm-serif;

  &.secondary {
    font-family: $font-lexend;
    font-size: 32px;
    line-height: 32px;
    text-transform: uppercase;
  }
  @media screen and (max-width: $breakpoint-md) {
    font-size: 32px;
    line-height: 32px;
    &.secondary {
      font-size: 24px;
      line-height: 24px;
    }
    // line-height: 56px;
  }
}

p {
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  margin-bottom: 32px;
  @media screen and (max-width: $breakpoint-md) {
    font-size: 16px;
    line-height: 25.6px;
  }
}

.bg-light-blue {
  background-color: $light-blue;
}

.mt-6 {
  margin-top: 24px;
}

.mt-7 {
  margin-top: 28px;
}

.mt-10 {
  margin-top: 40px;
}

.mt-16 {
  margin-top: 60px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 40px;
}

.mb-16 {
  margin-bottom: 60px;
}

.mb-20 {
  margin-bottom: 90px;
}

.pb-16 {
  padding-top: 60px;
}

.pb-16 {
  padding-bottom: 60px;
}

.appearing {
  animation: drop-in 0.5s ease 200ms backwards;

  &.delay {
    animation-delay: 0.3s;
  }
  &.delay-04 {
    animation-delay: 0.4s;
  }
  &.delay-05 {
    animation-delay: 0.5s;
  }
}

.appearing-right {
  animation: drop-right 0.5s ease 200ms backwards;

  &.delay {
    animation-delay: 0.3s;
  }
}

.appearing-line {
  .investment-wrapper {
    .title-wrapper,
    .text-wrapper {
      animation: fade-in 0.5s ease 200ms backwards;
    }

    .title-wrapper .h2,
    .text-wrapper .list,
    .text-wrapper p {
      animation: drop-in 0.5s ease 200ms backwards;
    }

    &:nth-child(2) {
      .title-wrapper,
      .text-wrapper,
      .title-wrapper .h2,
      .text-wrapper p,
      .text-wrapper .list {
        animation-delay: 0.4s;
      }
    }
    &:nth-child(3) {
      .title-wrapper,
      .text-wrapper,
      .title-wrapper .h2,
      .text-wrapper p,
      .text-wrapper .list {
        animation-delay: 0.5s;
      }
    }
    &:nth-child(4) {
      .title-wrapper,
      .text-wrapper,
      .title-wrapper .h2,
      .text-wrapper p,
      .text-wrapper .list {
        animation-delay: 0.6s;
      }
    }
  }
}

@keyframes drop-right {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translate(0px);
  }
}

@keyframes drop-in {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translate(0px);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
