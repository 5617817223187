.about-wrapper {
  display: flex;
  gap: 60px;

  min-height: calc(100vh - 148px - 147px);

  @media screen and (max-width: $breakpoint-lg) {
    min-height: calc(100vh - 72px - 147px);
  }

  @media screen and (max-width: $breakpoint-md) {
    min-height: calc(100vh - 72px - 106px);
  }

  @media screen and (max-width: $breakpoint-md) {
    flex-direction: column;
    gap: 0;
    .title-wrapper {
      padding-bottom: 60px;
      margin-top: 24px;
      .h2 {
        animation: none !important;
      }
    }
  }

  & > div {
    border-top: 2px solid $black;
  }

  .title-wrapper {
    width: 376px;
    min-width: 160px;
    max-width: 376px;
    padding: 32px 0;
    @media screen and (max-width: $breakpoint-md) {
      width: 100%;
      max-width: 100%;
    }
  }
  .text-wrapper {
    max-width: 845px;
    padding: 32px 0;
    p {
      &:nth-child(1) {
        animation: drop-in 0.5s ease 200ms backwards;
      }
      &:nth-child(1) {
        animation: drop-in 0.5s ease 200ms backwards;
        animation-delay: 0.1s;
      }
      &:nth-child(2) {
        animation: drop-in 0.5s ease 200ms backwards;
        animation-delay: 0.2s;
      }
      &:nth-child(3) {
        animation: drop-in 0.5s ease 200ms backwards;
        animation-delay: 0.3s;
      }
      &:nth-child(4) {
        animation: drop-in 0.5s ease 200ms backwards;
        animation-delay: 0.4s;
      }
      &:nth-child(5) {
        animation: drop-in 0.5s ease 200ms backwards;
        animation-delay: 0.5s;
      }
    }
  }
}
